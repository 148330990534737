import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Col, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import context from "../../../context/context";
import Header from "../../../components/header";
import Loader from "../../../components/Loader";
import toast from "react-hot-toast";
import sortByColumn from "../../../utility/sorting";
//import Context from "../../../context/context";
// import ReactTable from "react-table";
import ReactPaginate from "react-paginate";
import { useCallback } from "react";
import { debounce } from "underscore";
import ErrosToast from "../../../components/ErrosToast";
import { useSocketContext } from "../../../context/socketContext";

const ModifyUser = () => {
  const { emit, on } = useSocketContext();
  const navigate = useNavigate();
  // const [userlist, setUserlist] = useState([]);
  const { userlist, setUserlist } = useContext(context);
  const [isLoading, setIsLoading] = useState(false);
  const ctx = useContext(context);
  // const [lockStatus, setLockStatus] = useState("✅");
  //const [userActivtionStatus, setUserActivtionStatus] = useState("✅");

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [emojiOperator, setEmojiOperator] = useState({
    userName: "⬆",
    factory: "⬆",
    nveRangeId: "⬆",
  });
  const [dataInIncrement, setDataInIncrement] = useState([]);
  const [dataInDecrement, setDataInDecrement] = useState([]);
  const [showIncrement, setShowIncrement] = useState(true);

  const [userForSearch, setUserForSearch] = useState("");
  const [factForSearch, setFactForSearch] = useState("");
  const [nveIdForSearch, setNveIdForSearch] = useState("");

  const fetchData = async (
    pageNo,
    limit,
    username = "",
    fact = "",
    nveId = ""
  ) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_LINK}/api/v1/admin/getAllUsers`,
        {
          params: {
            pageNo: pageNo,
            docNo: limit,
            username: username,
            factory: fact,
            nveId: nveId,
          },
        }
      );
      setIsLoading(false);
      // console.log("userlist ", res.data);
      // setIsLoading(false);
      setUserlist(res.data.users);

      setPage(pageNo);

      if (res.data.users.length < limit) {
        setHasMore(false);
      }
      setInitialFetchDone(true);
      // ctx.setCurrentPageModifyUser(0);
      // ctx.setPageCountModifyUser(Math.ceil(res.data.pageCount));
    } catch (err) {
      // setIsLoading(false);
      // console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("hasMore", hasMore);

  useEffect(() => {
    // fetchData(0,50)

    if (!initialFetchDone) {
      setIsLoading(false);
      fetchData(0, 30, userForSearch, factForSearch, nveIdForSearch);
      // setInitialFetchDone(true); // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        // console.log("boxapi length", userlist.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !isLoading
        ) {
          fetchData(
            0,
            30 * ((userlist.length + 30) / 30),
            userForSearch,
            factForSearch,
            nveIdForSearch
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, isLoading]);

  // socket

  useEffect(() => {
    emit("users_room");
    on("online_status", (data) => {
      // console.log(data, "er");
      const current_user_list = [...userlist];
      // console.log(current_user_list);
      const new_list = current_user_list.map((x) => {
        if (x.username === data.username) {
          x.live_status = data.status;
          return x;
        } else {
          return x;
        }
      });
      // console.log(new_list, "new_list");
      setUserlist(new_list);
    });

    return () => {};
  }, [userlist]);

  const handlePageClick = (e) => {
    // for pagination handle function
    setIsLoading(true);

    axios
      .get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllUsers?pageNo=${e.selected}&docNo=${30}`
      )
      .then((res) => {
        //ctx.setPageCountModifyUser(Math.ceil((res.data.users.length/30)+1));
        setUserlist(res.data.users);
        ctx.setCurrentPageModifyUser(e.selected);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error if the axios request fails
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const UpdateUserDetails = (id) => {
    navigate(`/ModifyUser/${id}`);
  };

  const DeleteUser = async (id, i) => {
    setUserlist((prevData) =>
      prevData.map((users) =>
        users.id === id ? { ...users, isLoadingDelete: true } : users
      )
    );
    if (
      window.confirm(`Do you Want to delete the data of User Id :  ${id} ?`)
    ) {
      try {
        await axios
          .get(
            process.env.REACT_APP_API_LINK + "/api/v1/admin/deleteUser?id=" + id
          )
          .then((res) => {
            toast.success(`  User Id : ${id}  data Deleted`);
            setUserlist((prevData) => prevData.filter((el) => id !== el.id));
          });
      } catch (err) {
        // console.log(err);
      } finally {
        setUserlist((prevData) =>
          prevData.map((users) => ({
            ...users,
            isLoadingDelete: false,
          }))
        );
      }
    } else {
      setUserlist((prevData) =>
        prevData.map((users) => ({ ...users, isLoadingDelete: false }))
      );
      ErrosToast("No Record Deleted");
    }
  };

  const unlockUser = async (id, lock) => {
    // e.preventDefault();
    if (lock === false) {
      return ErrosToast("user is already unlocked");
    } else
      return axios
        .post("/admin/unlock", { username: id })
        .then((res) => {
          //console.log("resiyaaa", res);
          toast.success(`${id} , get unlocked`);
          setUserlist((prev) => {
            const newUpdated = prev.map((el) => {
              if (el.username === id) {
                return { ...el, locked: false };
                // console.log(...el ,  locked: "✅" )
              }
              return el;
            });
            return newUpdated;
            // return console.log("newupdatedone ", newUpdated);
          });
        })
        .catch((err) => {
          // console.log("errrr", err);
        });
  };
  const activateUser = (id, activate) => {
    if (activate === 0) {
      return ErrosToast("user is already activated");
    } else {
      return axios
        .post("/admin/reactivate", { username: id })
        .then(() => {
          toast.success(`${id} , get activated`);
          setUserlist((prev) => {
            const newUpdated = prev.map((el) => {
              if (el.username === id) {
                return { ...el, deactivated: 0 };
                // console.log(...el ,  locked: "✅" )
              }
              return el;
            });
            return newUpdated;
            // return console.log("newupdatedone ", newUpdated);
          });
        })
        .catch((err) => {
          // console.log("errrr", err);
        });
    }

    // alert("functionality is in process");
    //console.log('userlist', userlist)
    //  setUserlist([...userlist, {"deactivated": 1}])
    //  console.log('userlist', userlist)
    //  setDataInDecrement([...userlist, {"deactivated": 1}])
    //  setDataInIncrement([...userlist, {"deactivated": 1}])
  };

  useEffect(() => {
    setDataInDecrement(userlist);
    setDataInIncrement(userlist);
  }, [userlist]);

  const emojiOperatorChange = (e) => {
    const name = e.target.dataset.name;

    if (name === "userName") {
      setEmojiOperator({
        ...emojiOperator,
        userName: emojiOperator.userName === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        userlist,
        "username",
        emojiOperator.userName
      );
      emojiOperator.userName === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.userName === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "factory") {
      setEmojiOperator({
        ...emojiOperator,
        factory: emojiOperator.factory === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        userlist,
        "factory",
        emojiOperator.factory
      );
      emojiOperator.factory === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.factory === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    } else if (name === "nveRangeId") {
      setEmojiOperator({
        ...emojiOperator,
        nveRangeId: emojiOperator.nveRangeId === "⬆" ? "⬇" : "⬆",
      });
      const filteredData = sortByColumn(
        userlist,
        "barcodeRangeId",
        emojiOperator.nveRangeId
      );
      emojiOperator.nveRangeId === "⬆"
        ? setDataInDecrement(filteredData)
        : setDataInIncrement(filteredData);
      emojiOperator.nveRangeId === "⬆"
        ? setShowIncrement(true)
        : setShowIncrement(false);
    }
  };

  const filterHandle = async (
    e,
    userForSearch,
    factForSearch,
    nveIdForSearch
  ) => {
    setIsLoading(true);
    const encodedUser = encodeURIComponent(userForSearch);
    const encodedFact = encodeURIComponent(factForSearch);
    const encodedNveId = encodeURIComponent(nveIdForSearch);
    await axios
      .get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllUsers?pageNo=${0}&docNo=${30}&username=${encodedUser}&factory=${encodedFact}&nveId=${encodedNveId}`,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          // },
        }
      )
      .then((res) => {
        // console.log("userlist ", res.data);
        setIsLoading(false);
        setUserlist(res.data.users);
        // if (res.data.transferData.length < 30) {
        //   setHasMore(false);
        // }
        // else{
        setHasMore(true);
        // }
        // ctx.setCurrentPageModifyUser(0);
        // ctx.setPageCountModifyUser(Math.ceil(res.data.pageCount));
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (e, userForSearch, factForSearch, nveIdForSearch) =>
        filterHandle(e, userForSearch, factForSearch, nveIdForSearch),
      1000,
      false
    ),
    []
  );

  const handleReset = async () => {
    setIsLoading(true);
    await axios
      .get(
        process.env.REACT_APP_API_LINK +
          `/api/v1/admin/getAllUsers?pageNo=${0}&docNo=${30}`,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          // },
        }
      )
      .then((res) => {
        setInitialFetchDone(false);
        setUserForSearch("");
        setFactForSearch("");
        setNveIdForSearch("");
        // console.log("userlist ", res.data);
        setIsLoading(false);
        setUserlist(res.data.users);
        if (res.data.users.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        // ctx.setCurrentPageModifyUser(0);
        // ctx.setPageCountModifyUser(Math.ceil(res.data.pageCount));
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
      });
  };

  // console.log('userlist', userlist)
  // console.log('increment waka', dataInIncrement);
  // console.log('decrement wala', dataInDecrement)

  const currentData = showIncrement ? dataInDecrement : dataInIncrement;

  // console.log('current', currentData)
  // console.log('user search',userForSearch)
  // console.log('fact search',factForSearch)
  // console.log('nveId search',nveIdForSearch)

  return (
    <div>
      <div className="fixed-top">
        <Header />
        <div
          style={{
            background: "white",
            width: "100%",
            // marginLeft: "1.5%",
            padding: "0% 0% 0% 1.5%",
          }}
        >
          <Form autoComplete="off">
            <Row className="p-2 ">
              <Col lg={2}>
                <Form.Group>
                  <Form.Label className="fs-6">User</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    name="user"
                    // required
                    placeholder="User"
                    maxLength={12}
                    // id="input-search"
                    value={userForSearch}
                    onChange={(e) => {
                      setUserForSearch(e.target.value.replace(/%/g, ""));
                      OnChangeHandleFilter(
                        e,
                        e.target.value,
                        factForSearch,
                        nveIdForSearch
                      );
                    }}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group>
                  <Form.Label className="fs-6">Factory</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    // id="input-search"
                    name="fact"
                    value={factForSearch}
                    placeholder="Factory"
                    maxLength={5}
                    onChange={(e) => {
                      setFactForSearch(e.target.value.replace(/%/g, ""));
                      OnChangeHandleFilter(
                        e,
                        userForSearch,
                        e.target.value,
                        nveIdForSearch
                      );
                      // username=${userForSearch}&factory=${factForSearch}&nveId=${nveIdForSearch}
                    }}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group>
                  <Form.Label className="fs-6">NVE ID</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    id="input-search"
                    name="nve"
                    value={nveIdForSearch}
                    placeholder="NVE Id"
                    onChange={(e) => {
                      setNveIdForSearch(e.target.value.replace(/%/g, ""));
                      OnChangeHandleFilter(
                        e,
                        userForSearch,
                        factForSearch,
                        e.target.value
                      );
                    }}
                    maxLength={6}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>

              <Col className="mt-4 p-1">
                <Button
                  style={{ width: "80px" }}
                  className="btn btn-primary "
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    filterHandle(
                      e,
                      userForSearch,
                      factForSearch,
                      nveIdForSearch
                    );
                  }}
                >
                  Filter
                </Button>
                <Button
                  className="ms-3 btn btn-danger"
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Col>
              {/* <Col className="mt-4 p-1 " style={{ paddingLeft: "-10px" }}>
                <Button
                  style={{ width: "80px" }}
                  className="btn btn-danger "
                  type="reset"
                  onClick={() => {
                    alert("Functionality is yet to be done");
                  }}
                >
                  Reset
                </Button>
              </Col> */}
            </Row>
          </Form>
        </div>
      </div>

      {isLoading && !initialFetchDone ? (
        <div
          className="d-flex justify-content-center align-items-center loader-container"
          style={{ marginTop: "205px" }}
        >
          <Loader className="loader" />
        </div>
      ) : (
        <div
          className="mx-4 table-responsive tableFixHead table-container"
          style={{ marginTop: "190px", marginBottom: "40px" }}
        >
          <table className="table table-bordered border-dark ">
            <thead>
              <tr className="table-warning">
                <th>S.No.</th>
                <th>User Id</th>
                <th
                  data-name="userName"
                  onClick={emojiOperatorChange}
                >{`User Name${emojiOperator.userName}`}</th>
                <th>
                  Online Status({userlist.filter((x) => x.live_status).length})
                </th>
                <th>Lock Status</th>
                <th>Activation Status</th>
                <th
                  data-name="factory"
                  onClick={emojiOperatorChange}
                >{`Factory${emojiOperator.factory}`}</th>
                <th
                  data-name="nveRangeId"
                  onClick={emojiOperatorChange}
                >{`NVE Id${emojiOperator.nveRangeId}`}</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {currentData.length > 0 ? (
                currentData.map((ul, i) => {
                  return (
                    // console.log("ullllllllllllllllllllll", ul);
                    <tr key={ul.id} className="table-default">
                      <td>{i + 1}</td>
                      <td>
                        {/* {<a href={`/getUser?id=${ul.username}`}>{ul.username}</a> /} */}
                        <Link to={`/ModifyUser/${ul.id}`}>{ul.id}</Link>
                      </td>
                      <td>{ul.username}</td>
                      <td>{ul.live_status === true ? "\u2705" : "\u274C"}</td>
                      <td>
                        <button
                          // disabled={ul.locked === false}
                          onClick={() => {
                            unlockUser(ul.username, ul.locked);
                          }}
                        >
                          {/* &#9989; */}
                          {/* &#10060; */}
                          {ul.locked === false ? "\u2705" : "\u274C"}
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() =>
                            activateUser(ul.username, ul.deactivated)
                          }
                        >
                          {ul.deactivated === 0 ? "\u2705" : "\u274C"}
                        </button>
                      </td>
                      <td>{ul.factory}</td>
                      <td>{ul.barcodeRangeId || "Not assigned"}</td>
                      <td
                        className="d-flex justify-content-center"
                        style={{ border: "none" }}
                      >
                        <button
                          className="btn btn-info me-2"
                          onClick={() => UpdateUserDetails(ul.id)}
                        >
                          Modify
                        </button>
                        <span
                          class="d-inline-block"
                          tabindex="0"
                          data-toggle="tooltip"
                          title="You can't delete your own account"
                        >
                          <button
                            disabled={ctx.userData.id === ul.id}
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              DeleteUser(ul.id, ul.i);
                            }}
                          >
                            {ul.isLoadingDelete ? (
                              <Spinner size="sm" />
                            ) : (
                              "Delete"
                            )}
                          </button>
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan="14" className="text-center">
                  <h6>No data found</h6>
                </td>
              )}
              <tr></tr>
            </tbody>
          </table>
          {isLoading && initialFetchDone && (
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading && !hasMore && <div>No more data</div>}
          {/* {ctx.pageCountModifyUser && (
            <ReactPaginate
              disableInitialCallback={true}
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={ctx.pageCountModifyUser}
              forcePage={ctx.currentPageModifyUser}
              // initialPage={ctx.currentPage}

              previousLabel="< prev"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          )} */}
        </div>
      )}
    </div>
  );
};

export default ModifyUser;
