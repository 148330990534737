/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import _ from "underscore";

import convertRatio from "../../utility/convertRatio";
import nullify from "../../utility/nullify";
import Context from "../../context/context";
import ErrosToast from "../../components/ErrosToast";

const InputBox = ({
  po,
  index,
  updateLotTotals,
  updateTotalQtyFromInputBox,
}) => {
  const {
    userEntries,
    setUserEntries,
    poEntries,
    setPOEntries,
    isFilter,
    weightData,
    newHeader,
    poData,
  } = useContext(Context);
  let name, value;
  const [lot, setLot] = useState({});
  const [lotTotal, setLotTotal] = useState("Loading");
  const [allLotTotal, setAllLotTotal] = useState([]);
  //console.log(Object.keys(userEntries).length)
  // const [userInput, setUserInput] = useState({
  //   SZ01: Object.keys(userEntries).length > 0 ? userEntries[0].SZ01 : "",
  //   SZ02: Object.keys(userEntries).length > 0 ? userEntries[0].SZ02 : "",
  //   SZ03: Object.keys(userEntries).length > 0 ? userEntries[0].SZ03 : "",
  //   SZ04: Object.keys(userEntries).length > 0 ? userEntries[0].SZ04 : "",
  //   SZ05: Object.keys(userEntries).length > 0 ? userEntries[0].SZ05 : "",
  //   SZ06: Object.keys(userEntries).length > 0 ? userEntries[0].SZ06 : "",
  //   SZ07: Object.keys(userEntries).length > 0 ? userEntries[0].SZ07 : "",
  //   SZ08: Object.keys(userEntries).length > 0 ? userEntries[0].SZ08 : "",
  //   SZ09: Object.keys(userEntries).length > 0 ? userEntries[0].SZ09 : "",
  //   SZ10: Object.keys(userEntries).length > 0 ? userEntries[0].SZ10 : "",
  //   SZ11: Object.keys(userEntries).length > 0 ? userEntries[0].SZ11 : "",
  //   SZ12: Object.keys(userEntries).length > 0 ? userEntries[0].SZ12 : "",
  // });
  const [userInput, setUserInput] = useState({
    SZ01: "",
    SZ02: "",
    SZ03: "",
    SZ04: "",
    SZ05: "",
    SZ06: "",
    SZ07: "",
    SZ08: "",
    SZ09: "",
    SZ10: "",
    SZ11: "",
    SZ12: "",
  }); // this state holds the input entries by user in boxentry tab

  const [totalQty, setTotalQty] = useState(
    Object.values(userInput).reduce((a, b) => {
      if (typeof a === "number" && typeof b === "number") return a + Number(b);
      if (typeof a === "number") return a; // Return 'a' unchanged if 'b' is not a number
      if (typeof b === "number") return Number(b); // Return 'b' as a number if 'a' is not a number
      return 0;
    })
  );
  //console.log("user input", userInput);
  // const [inputValue, setInputValue] = useState(''); //state for storing input initial values

  //useEffect(() => {
  // setTotalQty(
  //   Object.values({ ...userInput, [name]: value }).reduce((i, n) => {
  //     let first = i === "" ? 0 : i;
  //     let second = n === "" ? 0 : n;
  //     // console.log('first i:',i, 'second n:', n)
  //     // console.log('first: ', first, ' second:', second)
  //     return first + second;
  //   })
  // );
  //setTotalQty(userEntries[0].SZ01 + userEntries)
  //}, userEntries)

  // useEffect(() => {}, [weightData.numOfBoxes]);

  useEffect(() => {
    if (isFilter)
      axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/v1/lot/getOne?LOT=" + po.LOT,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          //console.log("fetched lot");
          // console.log('lot data',response.data.lot)
          var lot = response.data.lot;
          setLot(lot);
          setLotTotal(
            Object.values(lot)
              .map((x) => parseInt(x))
              .reduce((c, n) => c + n)
          );
          const lots = poData.map((po) => po.LOT);
          // setParentLotTotal(lots)
          // setSeperateLotRation(Object.values(lot).map((x) => parseInt(x)).filter((l)=>{}));
        })
        .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    updateLotTotals(index, lotTotal);
  }, [lotTotal, index]);

  useEffect(() => {
    updateTotalQtyFromInputBox(index, totalQty);
  }, [totalQty, index]);

  // const handleToggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };
  // console.log('lot total', lotTotal)

  const handleTQty = (e) => {
    e.preventDefault();
    // console.log("po lot check in inputbox", po.LOT);
    let totks = e.target.value.replace(/\D/g, "");
    setTotalQty(totks);

    if (totks % lotTotal === 0) {
      const convertedValue = convertRatio(
        totks,
        Object.values(lot).map((x) => parseInt(x))
      );
      const finalValue = {
        SZ01: convertedValue[0],
        SZ02: convertedValue[1],
        SZ03: convertedValue[2],
        SZ04: convertedValue[3],
        SZ05: convertedValue[4],
        SZ06: convertedValue[5],
        SZ07: convertedValue[6],
        SZ08: convertedValue[7],
        SZ09: convertedValue[8],
        SZ10: convertedValue[9],
        SZ11: convertedValue[10],
        SZ12: convertedValue[11],
      };
      toast.remove();
      setUserInput(finalValue);
      console.log(convertedValue, "ppp");
      if (convertedValue.every((x) => x === 0 || x === "")) {
        setUserEntries(_.omit({ ...userEntries }, index));
        setPOEntries(_.omit({ ...poEntries }, index));
      } else {
        setUserEntries({ ...userEntries, [index]: { ...finalValue } });
        setPOEntries({ ...poEntries, [index]: po.id });
      }
    } else {
      // alert("here ou can enter onl multiples of" + lotTotal)
      toast.remove();
      ErrosToast(
        `  ${totks} is Incorrect Lot ratio . \n Here you can enter only multiples of :` +
          lotTotal
      );
      // setTotalQty("");
    }
  };
  const handleUserInput = (e) => {
    name = e.target.name;
    value = parseInt(e.target.value) || "";
    setUserInput({ ...userInput, [name]: value });

    let temp = {
      ...userEntries,
      [index]: { ...userInput, [name]: value },
    };
    let temp2 = { ...poEntries, [index]: po.id };
    if (
      Object.values({ ...userInput, [name]: value }).every(
        (x) => x === 0 || x === ""
      )
    ) {
      setUserEntries(_.omit({ ...userEntries }, index));
      setPOEntries(_.omit({ ...poEntries }, index));
    } else {
      setUserEntries(temp);
      setPOEntries(temp2);
    }
    let totQty = Object.values({ ...userInput, [name]: value }).reduce(
      (i, n) => {
        var first = i === "" ? 0 : i;
        var second = n === "" ? 0 : n;
        // if(first+second ===0){
        //   return ""
        // }
        return first + second;
      }
    );

    setTotalQty(totQty);
  };

  const lotRationInCell = (x) => {
    // x == 0 ? null : x;
    if (x === 0) {
      return null;
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            padding: "5px",
            color: "red",
            fontWeight: "bolder",
          }}
        >
          {nullify(x)}
        </div>
      );
    }
  };

  const inputStyle = {
    textAlign: "center",
  };
  if (po.LOT === "000") {
    inputStyle.marginTop = "33%";
  }

  // console.log('user entries', userEntries)
  // console.log('user input', userInput)
  //console.log('user entries third', userEntries[0].SZ03)
  console.log("taotal value", totalQty);

  return (
    <>
      <tr className="table-primary border-secondary mb-2">
        {isFilter && <th className="title-head">#PO_Qty</th>}
        <td>{po?.FACT}</td>
        <td>{po?.SUP}</td>
        <td>{po?.PO} </td>
        <td>{po?.SEA}</td>
        <td>{po?.STY}</td>
        <td>{po?.LOT}</td>
        <td>{po?.CLR}</td>
        <td>{po?.DIM}</td>
        <td>{po?.TOT_QTY}</td>
        <td>{nullify(po?.SZ01)}</td>
        <td>{nullify(po?.SZ02)}</td>
        <td>{nullify(po?.SZ03)}</td>
        <td>{nullify(po?.SZ04)}</td>
        <td>{nullify(po?.SZ05)}</td>
        <td>{nullify(po?.SZ06)}</td>
        <td>{nullify(po?.SZ07)}</td>
        <td>{nullify(po?.SZ08)}</td>
        <td>{nullify(po?.SZ09)}</td>
        <td>{nullify(po?.SZ10)}</td>
        <td>{nullify(po?.SZ11)}</td>
        <td>{nullify(po?.SZ12)}</td>
      </tr>
      {isFilter && (
        <>
          <tr className="table-warning border-secondary">
            <th>Packed_Qty</th>
            <td>{po?.packedQuantity?.FACT}</td>
            <td>{po?.packedQuantity?.SUP}</td>
            <td>{po?.packedQuantity?.PO} </td>
            <td>{po?.packedQuantity?.SEA}</td>
            <td>{po?.packedQuantity?.STY}</td>
            <td>{po?.packedQuantity?.LOT}</td>
            <td>{po?.packedQuantity?.CLR}</td>
            <td>{po?.packedQuantity?.DIM}</td>
            <td>
              {parseInt(po?.packedQuantity?.TOT_QTY) +
                nullify(totalQty * weightData.numOfBoxes)}
            </td>
            {/* <td>{3 + 4}</td> */}
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ01 || 0) +
                  parseInt(userInput.SZ01 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ02 || 0) +
                  parseInt(userInput.SZ02 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ03 || 0) +
                  parseInt(userInput.SZ03 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ04 || 0) +
                  parseInt(userInput.SZ04 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ05 || 0) +
                  parseInt(userInput.SZ05 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ06 || 0) +
                  parseInt(userInput.SZ06 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ07 || 0) +
                  parseInt(userInput.SZ07 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ08 || 0) +
                  parseInt(userInput.SZ08 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ09 || 0) +
                  parseInt(userInput.SZ09 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ10 || 0) +
                  parseInt(userInput.SZ10 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ11 || 0) +
                  parseInt(userInput.SZ11 * weightData.numOfBoxes) || 0
              )}
            </td>
            <td>
              {nullify(
                parseInt(po?.packedQuantity?.SZ12 || 0) +
                  parseInt(userInput.SZ12 * weightData.numOfBoxes) || 0
              )}
            </td>
          </tr>
          <tr className="table-danger border-secondary">
            <th>Balance_Qty</th>
            <td>{po?.balanceQuantity?.FACT}</td>
            <td>{po?.balanceQuantity?.SUP}</td>
            <td>{po?.balanceQuantity?.PO} </td>
            <td>{po?.balanceQuantity?.SEA}</td>
            <td>{po?.balanceQuantity?.STY}</td>
            <td>{po?.balanceQuantity?.LOT}</td>
            <td>{po?.balanceQuantity?.CLR}</td>
            <td>{po?.balanceQuantity?.DIM}</td>
            <td>
              {po?.balanceQuantity?.TOT_QTY - totalQty * weightData.numOfBoxes}
            </td>
            {/* <td>hiiii</td> */}
            <td>
              {nullify(
                po?.balanceQuantity?.SZ01 -
                  parseInt(nullify(userInput.SZ01 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ02 -
                  parseInt(nullify(userInput.SZ02 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ03 -
                  parseInt(nullify(userInput.SZ03 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ04 -
                  parseInt(nullify(userInput.SZ04 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ05 -
                  parseInt(nullify(userInput.SZ05 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ06 -
                  parseInt(nullify(userInput.SZ06 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ07 -
                  parseInt(nullify(userInput.SZ07 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ08 -
                  parseInt(nullify(userInput.SZ08 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ09 -
                  parseInt(nullify(userInput.SZ09 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ10 -
                  parseInt(nullify(userInput.SZ10 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ11 -
                  parseInt(nullify(userInput.SZ11 * weightData.numOfBoxes) || 0)
              )}
            </td>
            <td>
              {nullify(
                po?.balanceQuantity?.SZ12 -
                  parseInt(nullify(userInput.SZ12 * weightData.numOfBoxes) || 0)
              )}
            </td>
          </tr>

          {
            <tr className=" table-info border-secondary">
              <th>Tot_IP_Qty</th>
              <td>{po?.packedQuantity?.FACT}</td>
              <td>{po?.packedQuantity?.SUP}</td>
              <td>{po?.packedQuantity?.PO} </td>
              <td>{po?.packedQuantity?.SEA}</td>
              <td>{po?.packedQuantity?.STY}</td>
              <td>{po?.packedQuantity?.LOT}</td>
              <td>{po?.packedQuantity?.CLR}</td>
              <td>{po?.packedQuantity?.DIM}</td>
              <td>{nullify(totalQty * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ01 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ02 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ03 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ04 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ05 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ06 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ07 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ08 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ09 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ10 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ11 * weightData.numOfBoxes)}</td>
              <td>{nullify(userInput.SZ12 * weightData.numOfBoxes)}</td>
            </tr>
          }

          {lot !== {} && (
            <tr className="table-success border-secondary">
              <th>
                Ip_Qty <br />
                Qty/Box <br />
                Tot Box
              </th>
              <td>{po?.FACT}</td>
              <td>{po?.SUP}</td>
              <td>{po?.PO} </td>
              <td>{po?.SEA}</td>
              <td>{po?.STY}</td>
              <td>{po?.LOT}</td>
              <td>{po?.CLR}</td>
              <td>{po?.DIM}</td>
              <td>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "-2px",
                      transform: "translateX(-50%)",
                      textAlign: "center",
                      // padding: "2.5px",
                      // margin: '0 0 5px 0',
                      color: "red",
                      fontWeight: "bolder",
                    }}
                  >
                    {lotTotal === 0 ? null : lotTotal}
                  </div>

                  <input
                    style={{
                      position: "absolute",
                      top: "20px", // Adjust the top value to position the input relative to the parent
                      left: "50%", // Center the input horizontally within the parent
                      transform: "translateX(-50%)",
                      marginTop: "2px",
                      // paddingTop: "1px",
                      textAlign: "center",
                      backgroundColor: "beige",
                      width: "50px",
                    }}
                    type={"text"}
                    value={totalQty === 0 ? "" : totalQty}
                    onChange={handleTQty}
                    name="totalQty"
                    maxLength={4}
                    required
                    disabled={po.LOT === "000"}
                    autoComplete="off"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "45px",
                      textAlign: "center",
                      width: "100%",
                      padding: "5px",
                      color: "red",
                      fontWeight: "bolder",
                    }}
                  >
                    {weightData.numOfBoxes}
                  </div>
                </div>
              </td>
              <td>
                {lotRationInCell(lot.SR01)}
                <input
                  type={"text"}
                  style={inputStyle}
                  value={nullify(userInput.SZ01)}
                  onChange={handleUserInput}
                  maxLength={4}
                  name="SZ01"
                  disabled={newHeader[0] === "" || po.LOT !== "000"}
                  className={
                    newHeader[0] === ""
                      ? "hidden"
                      : nullify(userInput.SZ01) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ01
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR02)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ02)}
                  onChange={handleUserInput}
                  name="SZ02"
                  disabled={newHeader[1] === "" || po.LOT !== "000"}
                  className={
                    newHeader[1] === ""
                      ? "hidden"
                      : nullify(userInput.SZ02) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ02
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR03)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ03)}
                  onChange={handleUserInput}
                  name="SZ03"
                  disabled={newHeader[2] === "" || po.LOT !== "000"}
                  className={
                    newHeader[2] === ""
                      ? "hidden"
                      : nullify(userInput.SZ03) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ03
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR04)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ04)}
                  onChange={handleUserInput}
                  name="SZ04"
                  disabled={newHeader[3] === "" || po.LOT !== "000"}
                  className={
                    newHeader[3] === ""
                      ? "hidden"
                      : nullify(userInput.SZ04) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ04
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR05)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ05)}
                  onChange={handleUserInput}
                  name="SZ05"
                  disabled={newHeader[4] === "" || po.LOT !== "000"}
                  className={
                    newHeader[4] === ""
                      ? "hidden"
                      : nullify(userInput.SZ05) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ05
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR06)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ06)}
                  onChange={handleUserInput}
                  name="SZ06"
                  disabled={newHeader[5] === "" || po.LOT !== "000"}
                  className={
                    newHeader[5] === ""
                      ? "hidden"
                      : nullify(userInput.SZ06) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ06
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR07)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ07)}
                  onChange={handleUserInput}
                  name="SZ07"
                  disabled={newHeader[6] === "" || po.LOT !== "000"}
                  className={
                    newHeader[6] === ""
                      ? "hidden"
                      : nullify(userInput.SZ07) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ07
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR08)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ08)}
                  onChange={handleUserInput}
                  name="SZ08"
                  disabled={newHeader[7] === "" || po.LOT !== "000"}
                  className={
                    newHeader[7] === ""
                      ? "hidden"
                      : nullify(userInput.SZ08) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ08
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR09)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ09)}
                  onChange={handleUserInput}
                  name="SZ09"
                  disabled={newHeader[8] === "" || po.LOT !== "000"}
                  className={
                    newHeader[8] === ""
                      ? "hidden"
                      : nullify(userInput.SZ09) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ09
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR10)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ10)}
                  onChange={handleUserInput}
                  name="SZ10"
                  disabled={newHeader[9] === "" || po.LOT !== "000"}
                  className={
                    newHeader[9] === ""
                      ? "hidden"
                      : nullify(userInput.SZ10) === "" && po.LOT !== "000"
                      ? "hidden"
                      : userInput.SZ10
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR11)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ11)}
                  onChange={handleUserInput}
                  name="SZ11"
                  disabled={newHeader[10] === "" || po.LOT !== "000"}
                  className={
                    newHeader[10] === ""
                      ? "hidden"
                      : nullify(userInput.SZ11) === "" && po.LOT !== "000"
                  }
                  autoComplete="off"
                />
              </td>
              <td>
                {lotRationInCell(lot.SR12)}
                <input
                  type={"text"}
                  style={inputStyle}
                  maxLength={4}
                  value={nullify(userInput.SZ12)}
                  onChange={handleUserInput}
                  name="SZ12"
                  disabled={newHeader[11] === "" || po.LOT !== "000"}
                  className={
                    newHeader[11] === ""
                      ? "hidden"
                      : nullify(userInput.SZ12) === "" && po.LOT !== "000"
                  }
                  autoComplete="off"
                />
              </td>
            </tr>
          )}
          <br />
          {/* <hr /> */}
        </>
      )}
    </>
  );
};
export default InputBox;
