import axios from "axios";
import { createContext, useEffect, useState } from "react";
import convertHeader from "../utility/convertHeader";
import verifySizeHeading from "../utility/verifySizeHeading";
const userToken = localStorage.getItem("token");
axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
axios.defaults.baseURL = process.env.REACT_APP_API_LINK + "/api/v1";

axios
  .get("https://ipinfo.io/json?token=bafa3bee81d057")
  .then((res) => {
    localStorage.setItem("timezone", res.data.timezone);
  })
  .catch((err) => {
    localStorage.setItem("timezone", "Asia/Kolkata");
  });
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      alert("Your session has been expired. Please login again!");
      window.location.replace("/");
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

const OrderContext = createContext({
  openCheck: true,
  setOpenCheck: () => {},
  procesCheck: false,
  setProcesCheck: () => {},
  isFilter: true,
  setIsFilter: () => {},
  poData: [],
  setPoData: () => {},
  filterBody: {},
  setFilterBody: () => {},
  userEntries: {},
  setUserEntries: () => {},
  poEntries: {},
  setPOEntries: () => {},
  weightData: {},
  setWeightData: () => {},
  newHeader: [],
  setNH: () => {},
  getFilteredPoData: () => {},
  poDataDSP: [],
  setPoDataDSP: () => {},
  boxData: [],
  setBoxData: () => {},
  entries: [],
  lastTo: "",
  setLastTo: () => {},
  userData: {},
  setUserData: () => {},
  poSummary: [],
  setPoSummary: () => {},
  totalQty: "",
  setTotalQty: () => {},
  totalCount: 0,
  setTotalCount: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  currentPageModifyUser: 0, // current page for modify user
  setCurrentPageModifyUser: () => {},
  currentPagePoSummary: 0, // current page for page summary
  setCurrentPagePoSummary: () => {},
  currentPageTransferData: 0, // current page for transfer data
  setCurrentPageTransferData: () => {},
  currentPageBoxEntries: 0, // current page for box entries
  setCurrentPageBoxEntries: () => {},
  currentPageRangeCode: 0, // current page for rangecode
  setCurrentPageRangeCode: () => {},
  currentPageBoxType: 0, // current page for boxtype
  setCurrentPageBoxType: () => {},
  id: {},
  setId: () => {},
  filterClicked: false,
  setFilterClicked: () => {},
  userlist: [],
  setUserlist: () => {},
  selectedItems: [], // for selected items in exfactory which will also use in shipmentLoading
  setSelectedItems: () => {},
  processItems: [], // to store data in sent or send data from exFactory to shipment Loading
  setProcessItems: () => {},
  closedPO: [], // to store po in sent in closed PO in POsUMMARY
  setClosedPO: () => {},
  formData: {}, // to store data for shipInfo
  setFormData: () => {},
  overshipValue: 0, // to store overshipValue
  setOvershipValue: () => {},
  lastBCGeneratedSNo: 0, // to store if insert is allowed for this range or not in boxEntries
  setLastBCGeneratedSNo: () => {},
  entryBySeqID: [], // to store data of seqID entry in boxDetails Modify
  setentryBySeqID: () => {},
  rangeApi: [],
  setRangeApi: () => {}, // to store RangeCode Api Data
});
export const ContextProvider = (props) => {
  const [id, setId] = useState({
    PO: "",
    SEA: "",
    STY: "",
    LOT: "",
    CLR: "",
    DIM: "",
    FACT: "",
    SUP: "",
    AGNT: "",
  });

  const [isFilter, setIsFilter] = useState(false);
  const [openCheck, setOpenCheck] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [overshipValue, setOvershipValue] = useState(0);
  const [pageCountPoSummary, setPageCountPoSummary] = useState(0); // state for pagination pageCount in POSummary
  const [pageCountModifyUser, setPageCountModifyUser] = useState(0); // state for pagination pageCount in modifyUser
  const [pageCountTransferData, setPageCountTransferData] = useState(0); // state for pagination pageCount in transfer data
  const [pageCountBoxEntries, setPageCountBoxEntries] = useState(0); // state for pagination pageCount in box entries
  const [pageCountRangeCode, setPageCountRangeCode] = useState(0); // state for pagination pageCount in rangecode
  const [pageCountBoxType, setPageCountBoxType] = useState(0); // state for pagination pageCount in boxtype

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPagePoSummary, setCurrentPagePoSummary] = useState(0); // state for currentpage in pagination in POSummary
  const [currentPageModifyUser, setCurrentPageModifyUser] = useState(0); // state for currentpage in pagination in modifyUser
  const [currentPageTransferData, setCurrentPageTransferData] = useState(0); // state for currentpage in pagination in TransferData
  const [currentPageBoxEntries, setCurrentPageBoxEntries] = useState(0); // state for current page in pagination in box entries
  const [currentPageRangeCode, setCurrentPageRangeCode] = useState(0); // state for current page in pagination in rangecode
  const [currentPageBoxType, setCurrentPageBoxType] = useState(0); // state for current page in pagination in boxtype
  const [procesCheck, setProcesCheck] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [userData, setUserData] = useState(false);
  const [rerender, setrerender] = useState(1);

  const [filterBody, setFilterBody] = useState({
    PO: "",
    SEA: "",
    STY: "",
    LOT: "",
    CLR: "",
  });
  const [poData, setPoData] = useState([]); //for input boxes
  const [poDataDSP, setPoDataDSP] = useState([]); //for dsp page
  const [boxData, setBoxData] = useState([]); //for dsp page
  const [entryBySeqID, setentryBySeqID] = useState([]); //for boxDetail, modify  form data entry by seq id

  const [userEntries, setUserEntries] = useState({});
  const [poEntries, setPOEntries] = useState({});
  const [weightData, setWeightData] = useState({
    numOfBoxes: 1,
  });
  const [entries, setEntries] = useState([]);
  const [lastTo, setLastTo] = useState(1);
  const [newHeader, setNH] = useState([]);
  const [poSummary, setPoSummary] = useState([]);
  const [totalQty, setTotalQty] = useState("");
  const [totalCount, setTotalCount] = useState(0); // for notification batch

  //const [popupTimeout, setPopupTimeout] = useState(false) // for timeout pop up

  // ExportCsv
  const [csvSent, setCsvSent] = useState(false);
  const [csvUnsent, setCsvUnsent] = useState(true);
  const [boxDetailSent, setboxDetailSent] = useState(true);
  const [boxDetailUnsent, setboxDetailUnsent] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedPo, setSelectedPo] = useState("");

  const [filterClicked, setFilterClicked] = useState(false); //in DSP checking filter is clicked or not

  const [userlist, setUserlist] = useState([]); //all users from ModifyUser

  const [selectedItems, setSelectedItems] = useState([]); // for selected items in exfactory which will also use in shipmentLoading
  const [processItems, setProcessItems] = useState([]); // to store data in sent or send data from exFactory to shipment Loading
  const [closedPO, setClosedPO] = useState([]); //to store po in sent in closed PO in POsUMMARY

  const [formData, setFormData] = useState({
    // for shipInfo data
    shipNo: "",
    shipGroup: "",
    shipmentGroup: "",

    awbbl1: "",
    awbbl2: "",
    awbbl3: "",
    awbbl4: "",
    awbbl5: "",
    //  awbbl6: "",
    awbbldate: null,
    docStatus: "",
    etd: null,
    etaLager: null,
    lastEtaLager: null,
    deliveryNo: "",
    shipVia: "",
    description: "",
    etaPort: null,
    currentTime: null,
    warehouse: "",
    // shipmentCreationDate: "",
    countryOfOrigin: "",
    // countryName: "",
    cartons1: "",
    cartons2: "",
    cartons3: "",
    cartons4: "",
    cartons5: "",
    // cartons6: "",
    weightInKg: "",
    containerNo1: "",
    containerSize1: "",
    volume1: "",
    containerNo2: "",
    containerSize2: "",
    volume2: "",
    containerNo3: "",
    containerSize3: "",
    volume3: "",
    containerNo4: "",
    containerSize4: "",
    volume4: "",
    containerNo5: "",
    containerSize5: "",
    volume5: "",

    mv: "",
    portOfDispatch: "",
    resolutionPlan: "",
    // dispatchName: "",
    pieces: "",
    // lager: "",
    shipmentCrDate: null,
    factory: "",
    consignee: "LERROS",
    // consignee: "Lerros",
    actualCarrier: "",
    destination: "",
    fltVoyageTruck: "",
    actualEtd: null,
    actualEta: null,
    // cargo
    cargoDate: null,
  });
  const [lastBCGeneratedSNo, setLastBCGeneratedSNo] = useState(0); // to check insertion allowed or not in boxEntries
  const [rangeApi, setRangeApi] = useState([]); // to store range api data
  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios
        .get("/auth/getProfile")
        .then((res) => setUserData(res.data.user))
        .catch((err) => {
          // console.log(err)
        });
    }
  }, []);
  // console.log("user data", userData);
  useEffect(() => {
    if (verifySizeHeading(poData) && poData.length >= 1)
      setNH(convertHeader(poData[0].SIZE));

    return () => {};
  }, [poData]);

  const getFilteredPoData = async (filterBody, setIn) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_LINK + "/api/v1/po/getFilteredPurchaseOrders",
        filterBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIn === "po"
        ? setPoData(res.data.poData)
        : setPoDataDSP(res.data.poData);
      setLastTo(res.data.lastValueOfTo);
      return res.data.poData;
    } catch (err) {
      // console.log("xxxxxxx", err);
      alert("failed!");
    }
  };

  const blankUserState = {
    id: "", // for comparing the current user and updated user in modifyUser.
    username: "",
    password: "",
    supplier: "",
    factory: "",
    company: "Lerros",
    agent: "",
    selectedBR: "",
    user: false,
    admin: false,
    dsp: false,
    boxDetails: false,
    shipInfo: false,
    entry: false,
    entryModification: false,
    csv: false,
    orders: false,

    createUser: false,
    modifyUser: false,
    loadPO: false,
    boxType: false,
    rangeCode: false,
    barcodeRangeId: "Select",
    etdAccess: false,
    overshipAccess: false,
    deletePoEnteriesAccess: false, // new field
    defaultOvership: 3,
    barcodePdf: false,
    exFactory: false,
    shipmentLoading: false,
    reports: false,
  };

  const [formDataReport, setformDataReport] = useState({
    etdFrom: "",
    koll: "",
    factory: "",
    agent: "",
    style: "",
    div: "",
    openedPo: false,
    closedPo: false,
    exFactory: false,
  });

  return (
    <OrderContext.Provider
      value={{
        id,
        setId,
        poSummary,
        setPoSummary,
        openCheck,
        setOpenCheck,
        procesCheck,
        setProcesCheck,
        isAuthorized,
        setIsAuthorized,
        isFilter,
        setIsFilter,
        poData,
        setPoData,
        filterBody,
        setFilterBody,
        userEntries,
        setUserEntries,
        poEntries,
        setPOEntries,
        weightData,
        setWeightData,
        newHeader,
        setNH,
        getFilteredPoData,
        poDataDSP,
        setPoDataDSP,
        boxData,
        setBoxData,
        entries,
        setEntries,
        lastTo,
        setLastTo,
        userData,
        setUserData,
        totalQty,
        setTotalQty,
        totalCount,
        setTotalCount,
        pageCount,
        setPageCount,
        pageCountPoSummary, // page count is for pagination in po summary
        setPageCountPoSummary,
        pageCountModifyUser, // page count is for pagination in modify user
        setPageCountModifyUser,
        pageCountTransferData, // page count is for pagination in Transfer Data
        setPageCountTransferData,
        pageCountBoxEntries, // page count is for pagination in BoxEntries
        setPageCountBoxEntries,
        pageCountRangeCode, // page count is for pagination in rangecode
        setPageCountRangeCode,
        pageCountBoxType, // page count is for pagination in boxtype
        setPageCountBoxType,
        currentPage,
        setCurrentPage,
        currentPagePoSummary, // current page is for pagination in PO summary
        setCurrentPagePoSummary,
        currentPageModifyUser, // current page is for pagination in modify user
        setCurrentPageModifyUser,
        currentPageTransferData, // current page is for pagination in Transfer data
        setCurrentPageTransferData,
        currentPageBoxEntries, // current page is for pagination in Box entries
        setCurrentPageBoxEntries,
        currentPageRangeCode, // current page is for pagination in rangecode
        setCurrentPageRangeCode,
        currentPageBoxType, // current page is for pagination in boxtype
        setCurrentPageBoxType,
        csvSent,
        setCsvSent,
        csvUnsent,
        setCsvUnsent,
        boxDetailSent,
        setboxDetailSent,
        boxDetailUnsent,
        setboxDetailUnsent,
        rerender,
        setrerender,
        selectedPo,
        setSelectedPo,
        blankUserState,
        selectedCheckboxes,
        setSelectedCheckboxes,
        filterClicked,
        setFilterClicked,
        userlist,
        setUserlist,
        selectedItems,
        setSelectedItems,
        processItems,
        setProcessItems,
        closedPO,
        setClosedPO,
        formData,
        setFormData,
        overshipValue,
        setOvershipValue,
        lastBCGeneratedSNo,
        setLastBCGeneratedSNo,
        entryBySeqID,
        setentryBySeqID,
        rangeApi,
        setRangeApi,
        formDataReport,
        setformDataReport,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
