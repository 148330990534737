import { useEffect, useState, useContext } from "react";
import Header from "../../components/header";
import BarChart from "./BarChart";
import axios from "axios";
import Context from "../../context/context";
import "./Reports.css";
import GraphLoader from "../../components/GraphLoader";
// import Loader from "../../components/Loader";

const Reports = () => {
  const ctx = useContext(Context);
  // console.log("line9", ctx);
  const [loading, setLoading] = useState(false); // Loader state is initially false
  const [data, setData] = useState();
  // console.log("line8", data);
  const [formDataReport, setformDataReport] = useState({
    etdFrom: "",
    koll: "",
    factory: "",
    agent: "",
    style: "",
    div: "",
    openedPo: false,
    closedPo: false,
    exFactory: false,
  });

  // console.log("line24", formDataReport, typeof formDataReport.agent);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setformDataReport((prevformDataReport) => ({
      ...prevformDataReport,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    // Define the async function inside useEffect
    const fetchData = async () => {
      setLoading(true); // Start loader when fetching starts

      // Build the API URL
      const apiUrl = `${process.env.REACT_APP_API_LINK}/api/v1/getReports/reports`;

      // Prepare query parameters
      const params = {
        ETD: formDataReport.etdFrom || "",
        koll: formDataReport.koll ? `"${formDataReport.koll}"` : "",
        STY: formDataReport.style ? `"${formDataReport.style}"` : "",
        FACT: formDataReport.factory ? `"${formDataReport.factory}"` : "",
        AGNT: formDataReport.agent ? `"${formDataReport.agent}"` : "",
        DIV: formDataReport.div || "",
        openPO: formDataReport.openedPo ? "true" : "",
        closePo: formDataReport.closedPo ? "true" : "",
        exFactory: formDataReport.exFactory ? "true" : "",
      };

      try {
        // Make the API call using axios
        const response = await axios.get(apiUrl, { params });

        // Handle the response
        setData(response.data); // Set the data in state
      } catch (error) {
        // console.log("API is not working", error); // Handle the error
        // setLoading(false); // Set loading to false on error
      } finally {
        setLoading(false); // Stop loader when fetch is complete
      }
    };

    fetchData(); // Call the async function
  }, [formDataReport]); // Dependencies
  return (
    <div>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
      </div>
      {/* Loader Component - Show loader while data is fetching */}

      <div
        style={{
          marginTop: "87px",
          background: "white",
          // border: "2px solid red",
          padding: "1% 1%",
        }}
      >
        <form
          className="mx-4 "
          style={{
            background: "white",
            padding: "1% 1%",
            border: "2px solid red",
          }}
        >
          <div className="form-row d-flex row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2px 1px ",
              }}
            >
              <label className="fw-bold fs-5" htmlFor="etdFrom">
                ETD From:
              </label>
              <select
                id="etdFrom"
                name="etdFrom"
                value={formDataReport.etdFrom}
                onChange={handleChange}
                className="btn-group btn-lg dropdown-toggle"
                style={{
                  height: "5vh",
                  width: "120px",
                  padding: "5px",
                  fontSize: "15px",
                  color: "brown",
                  border: "1px solid black",
                }}
              >
                <option value="" style={{ cursor: "pointer" }}>
                  Select ETD
                </option>{" "}
                {/* Default option */}
                {data &&
                  // Use Set to filter unique values
                  [...new Set(data.map((x) => x.ETD))].map((uniqueETD, i) => (
                    <option value={uniqueETD} key={i}>
                      {uniqueETD}
                    </option>
                  ))}
              </select>
            </div>

            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-between",
                padding: "2px 1px ",
              }}
            >
              <div
                style={{
                  // border: "2px solid green",
                  width: "103px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label className="fw-bold fs-5" htmlFor="koll">
                  Koll:
                </label>
              </div>
              <div
                style={{
                  // border: "2px solid blue",
                  width: "131px",
                }}
              >
                <select
                  id="koll"
                  name="koll"
                  value={formDataReport.koll}
                  onChange={handleChange}
                  className="btn-group btn-lg dropdown-toggle"
                  style={{
                    height: "5vh",
                    width: "120px",
                    padding: "5px",
                    fontSize: "15px",
                    color: "brown",
                    border: "1px solid black",
                  }}
                >
                  <option value="">Select koll</option>
                  {data &&
                    // Use Set to filter unique values
                    [...new Set(data.map((x) => x.koll))].map(
                      (uniquekoll, i) => (
                        <option value={uniquekoll} key={i}>
                          {uniquekoll}
                        </option>
                      )
                    )}
                </select>
              </div>
            </div>
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2px 1px ",
              }}
            >
              <label className="fw-bold fs-5" htmlFor="factory">
                Factory:
              </label>
              <select
                id="factory"
                name="factory"
                value={formDataReport.factory}
                onChange={handleChange}
                className="btn-group btn-lg dropdown-toggle"
                style={{
                  height: "5vh",
                  width: "120px",
                  padding: "5px",
                  fontSize: "15px",
                  color: "brown",
                  border: "1px solid black",
                }}
              >
                <option value="">Select Factory</option>
                {data &&
                  // Use Set to filter unique values
                  [...new Set(data.map((x) => x.FACT))].map((uniqueFACT, i) => (
                    <option value={uniqueFACT} key={i}>
                      {uniqueFACT}
                    </option>
                  ))}
              </select>
            </div>
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2px 1px ",
              }}
            >
              <label className="fw-bold fs-5" htmlFor="agent">
                Agent:
              </label>
              <select
                id="agent"
                name="agent"
                value={formDataReport.agent}
                onChange={handleChange}
                className="btn-group btn-lg dropdown-toggle"
                style={{
                  height: "5vh",
                  width: "120px",
                  padding: "5px",
                  fontSize: "15px",
                  color: "brown",
                  border: "1px solid black",
                }}
              >
                <option value="">Select Agent</option>
                {data &&
                  // Use Set to filter unique values
                  [...new Set(data.map((x) => x.AGNT))].map((uniqueAGNT, i) => (
                    <option value={uniqueAGNT} key={i}>
                      {uniqueAGNT}
                    </option>
                  ))}
              </select>
            </div>
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-evenly",
                padding: "2px 1px ",
              }}
            >
              {" "}
              <label className="fw-bold fs-5" htmlFor="style">
                Style:
              </label>
              <select
                id="style"
                name="style"
                value={formDataReport.style}
                onChange={handleChange}
                className="btn-group btn-lg dropdown-toggle"
                style={{
                  height: "5vh",
                  width: "120px",
                  padding: "5px",
                  fontSize: "15px",
                  color: "brown",
                  border: "1px solid black",
                }}
              >
                <option value="">Select style</option>
                {data &&
                  // Use Set to filter unique values
                  [...new Set(data.map((x) => x.STY))].map((uniqueSTY, i) => (
                    <option value={uniqueSTY} key={i}>
                      {uniqueSTY}
                    </option>
                  ))}
              </select>
            </div>
            <div id="div_main">
              <div id="div_">
                <label className="fw-bold fs-5" htmlFor="div">
                  Div:
                </label>
              </div>
              <div
                style={{
                  // border: "2px solid red",
                  width: "144px",
                }}
              >
                <select
                  id="div"
                  name="div"
                  value={formDataReport.div}
                  onChange={handleChange}
                  className="btn-group btn-lg dropdown-toggle div"
                >
                  <option value="">Select div</option>
                  {data &&
                    // Use Set to filter unique values
                    [...new Set(data.map((x) => x.div))].map((uniqueDiv, i) => (
                      <option value={uniqueDiv} key={i}>
                        {uniqueDiv}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div id="openpo_main" class="form-check">
              <div
                id="openpo_"
                style={{
                  // border: "2px solid blue",
                  paddingLeft: "5px",
                }}
              >
                <label className="fw-bold fs-5" htmlFor="openedPo">
                  Opened PO:
                </label>
              </div>
              <div id="openpo">
                <input
                  type="checkbox"
                  id="openedPo"
                  name="openedPo"
                  checked={formDataReport.openedPo}
                  onChange={handleChange}
                  className="form-check-input"
                />
              </div>
            </div>

            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              class="form-check"
            >
              {" "}
              <div
                style={{
                  // border: "2px solid blue",
                  paddingLeft: "5px",
                }}
              >
                <label
                  className="fw-bold fs-5"
                  htmlFor="closedPo"
                  style={{ border: "2px solid white" }}
                >
                  Closed PO:
                </label>
              </div>
              <div id="closedpo">
                <input
                  type="checkbox"
                  id="closedPo"
                  name="closedPo"
                  checked={formDataReport.closedPo}
                  onChange={handleChange}
                  className="form-check-input"
                />
              </div>
            </div>
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              class="form-check"
            >
              {" "}
              <div
                style={{
                  // border: "2px solid blue",
                  paddingLeft: "5px",
                }}
              >
                <label
                  className="fw-bold fs-5"
                  htmlFor="exFactory"
                  // style={{ border: "2px solid green" }}
                >
                  ExFactory:
                </label>
              </div>
              <div id="exfactory">
                <input
                  type="checkbox"
                  id="exFactory"
                  name="exFactory"
                  checked={formDataReport.exFactory}
                  onChange={handleChange}
                  className="form-check-input"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* Display loader when data is being fetched */}
      {loading ? <GraphLoader /> : <BarChart chartData={data} />}
    </div>
  );
};
export default Reports;
