import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom"; // Import zoom plugin
import "./Barchart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin // Register zoom plugin
);

const BarChart = ({ chartData = [] }) => {
  // Default value for chartData
  if (!Array.isArray(chartData)) {
    console.error("chartData should be an array");
    return null; // Optionally return null or a fallback UI
  }

  const preprocessDate = (dateString) => {
    if (dateString.length === 8) {
      // Assuming YYYYMMDD format
      return `${dateString.slice(0, 4)}-${dateString.slice(
        4,
        6
      )}-${dateString.slice(6, 8)}`;
    }
    return dateString; // Return as is if format is not recognized
  };

  const formatDate = (dateString) => {
    const processedDate = preprocessDate(dateString);
    const date = new Date(processedDate);
    if (isNaN(date.getTime())) {
      console.warn(`Invalid date string: ${dateString}`);
      return "Unknown Date";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const data = {
    labels: chartData.map((item) => {
      const formattedDate = item.ETD ? formatDate(item.ETD) : "Unknown Date";
      // console.log(
      //   `Original ETD: ${item.ETD}, Formatted Date: ${formattedDate}`
      // );
      return formattedDate;
    }),
    datasets: [
      {
        label: "Total Po Quantity",
        backgroundColor: "blue",
        data: chartData.map((items) => items.total_qty || 0), // Fallback to 0 if undefined
      },
      {
        label: "Total Pack Quantity",
        backgroundColor: "red",
        data: chartData.map((items) => items.total_packed || 0), // Updated to total_packed
      },
      {
        label: "Total Exfactory Quantity",
        backgroundColor: "green",
        data: chartData.map((items) => items.total_exfactory || 0), // Fallback to 0 if undefined
      },
      {
        label: "Total Ship Quantity",
        backgroundColor: "purple",
        data: chartData.map((items) => items.total_ship || 0), // Fallback to 0 if undefined
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Productivity and Cost Saving in Sewing Department",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x", // Allow panning in both x and y directions
        },
        zoom: {
          enabled: true,
          mode: "x", // Allow zooming in both x and y directions
          speed: 0.1, // Adjust the zoom speed
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="chart">
        <Bar data={data} options={options} id="bar" />
      </div>
    </div>
  );
};

export default BarChart;
